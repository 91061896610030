import { Controller } from '@hotwired/stimulus'
import type Hls from 'hls.js'

type ChaptersManifestTitle = {
  language: string
  title: string
}
type ChaptersManifestChapter = {
  chapter: number
  'start-time': number
  titles: Array<ChaptersManifestTitle>
}
type ChaptersManifest = Array<ChaptersManifestChapter>

type VoiceParams = {
  buttonLabel: string
  audioUrl: string
  chaptersUrl: string
  voiceName: string
  voiceDescription: string
  voiceFlagCode: string
  voiceAuxilaryText: string
  voiceId: string
}

type MusicParams = {
  buttonLabel: string
  audioUrl: string
}

// Connects to data-controller="player"
export default class extends Controller<HTMLElement> {
  static targets: string[] = [
    'voiceAudio',
    'musicAudio',
    'play',
    'pause',
    'elapsedTime',
    'remainingTime',
    'currentChapter',
    'timeline',
    'voiceButtonLabel',
    'musicButtonLabel',
    'changeVoiceSelector',
    'changeMusicSelector',
    'currentVoiceFlag',
    'currentVoiceName',
    'currentVoiceDescription',
    'currentVoiceAuxilary',
    'currentVoiceoverArtistIdParam',
    'track',
  ]

  declare hlsModule: typeof Hls

  declare readonly voiceAudioTarget: HTMLAudioElement
  declare readonly musicAudioTarget?: HTMLAudioElement
  declare readonly hasMusicAudioTarget: boolean
  declare readonly playTargets: Array<HTMLButtonElement>
  declare readonly pauseTargets: Array<HTMLButtonElement>
  declare readonly elapsedTimeTarget: HTMLSpanElement
  declare readonly hasElapsedTimeTarget: boolean
  declare readonly remainingTimeTarget: HTMLSpanElement
  declare readonly hasRemainingTimeTarget: boolean
  declare readonly currentChapterTarget?: HTMLSpanElement
  declare readonly hasCurrentChapterTarget: boolean
  declare readonly timelineTarget: HTMLInputElement
  declare readonly hasTimelineTarget: boolean
  declare readonly voiceButtonLabelTarget: HTMLSpanElement
  declare readonly musicButtonLabelTarget: HTMLSpanElement
  declare readonly changeVoiceSelectorTargets: Array<HTMLElement>
  declare readonly changeMusicSelectorTargets: Array<HTMLElement>
  declare readonly currentVoiceFlagTarget: HTMLSpanElement
  declare readonly currentVoiceNameTarget: HTMLSpanElement
  declare readonly currentVoiceDescriptionTarget: HTMLSpanElement
  declare readonly currentVoiceAuxilaryTarget: HTMLSpanElement
  declare readonly currentVoiceoverArtistIdParamTargets: Array<HTMLInputElement>
  declare readonly trackTargets: Array<HTMLElement>
  declare readonly hasTrackTarget: boolean

  static values = {
    voiceAudioUrl: String,
    musicAudioUrl: String,
    preloadAudio: { type: Boolean, default: true },
    voiceChaptersUrl: String,
    currentChapterTitle: String,
    currentTimestamp: { type: Number, default: 0 },
    selectableTracks: { type: Boolean, default: false },
    currentVoiceId: String,
    autoplayNextTrack: { type: Boolean, default: false },
    tracklistRestarted: { type: Boolean, default: false },
  }

  declare voiceAudioUrlValue?: string
  declare musicAudioUrlValue?: string
  declare preloadAudioValue: boolean
  declare voiceChaptersUrlValue?: string
  declare chaptersManifest?: ChaptersManifest
  declare voiceHls?: Hls
  declare musicHls?: Hls
  declare currentChapterTitleValue?: string
  declare currentTimestampValue: number
  declare selectableTracksValue: boolean
  declare currentVoiceIdValue?: string
  declare autoplayNextTrackValue: boolean
  declare tracklistRestartedValue: boolean

  contentTypes: { [key: string]: string | undefined } = {}

  voiceAudioTargetConnected(target: HTMLAudioElement) {
    if (target.dataset.audioUrl) {
      this.voiceAudioUrlValue = target.dataset.audioUrl
    }

    if (target.dataset.chaptersUrl) {
      this.voiceChaptersUrlValue = target.dataset.chaptersUrl
    }

    if (target.dataset.currentVoiceId) {
      this.currentVoiceIdValue = target.dataset.currentVoiceId
    }

    // If the player is not playing, treat selecting a track like a play action.
    // If the player is already playing, no-op to let the voiceAudioUrlValueChanged function
    // restart the voice without pausing the music.
    // Browsers will automatically prevent this from autoplaying on initial page load
    // until user action is taken.
    if (this.tracklistRestartedValue) {
      // If resetTrackList caused this to reconnect, do not autoplay the track but toggle
      // tracklistRestartedValue so that the next track selection functions as expected
      this.tracklistRestartedValue = false
    } else if (this.selectableTracksValue && !this.isPlaying) {
      this.play()
    }
  }

  musicAudioTargetConnected(target: HTMLAudioElement) {
    if (target.dataset.audioUrl) {
      this.musicAudioUrlValue = target.dataset.audioUrl
    }

    if (target.dataset.volume) {
      target.volume = parseFloat(target.dataset.volume)
    }
  }

  disconnect(): void {
    this.voiceHls?.destroy()
    this.musicHls?.destroy()
  }

  async fetchVoiceAudio(): Promise<void> {
    if (!this.voiceAudioUrlValue) return

    this.voiceHls = await this.updateAudio(
      this.voiceAudioUrlValue,
      this.voiceHls,
      this.voiceAudioTarget
    )
  }

  async fetchMusicAudio(): Promise<void> {
    if (!this.hasMusicAudioTarget || !this.musicAudioTarget) return

    if (this.musicAudioUrlValue) {
      this.musicHls = await this.updateAudio(
        this.musicAudioUrlValue,
        this.musicHls,
        this.musicAudioTarget
      )
    } else {
      this.musicAudioTarget.pause()

      this.musicHls?.destroy()
      this.musicHls = undefined
      this.musicAudioTarget.src = ''
    }
  }

  async voiceAudioUrlValueChanged(): Promise<void> {
    if (!this.preloadAudioValue && !this.isPlaying) return

    await this.fetchVoiceAudio()

    this.voiceAudioTarget.currentTime = 0

    this.currentVoiceoverArtistIdParamTargets.forEach((element) => {
      element.value = this.currentVoiceIdValue ?? ''
    })

    if (this.isPlaying) {
      this.voiceAudioTarget.play()
    }
  }

  currentChapterTitleValueChanged(): void {
    if (!this.hasCurrentChapterTarget || !this.currentChapterTarget) return

    this.currentChapterTarget.innerText = this.currentChapterTitleValue ?? ''
  }

  async musicAudioUrlValueChanged(): Promise<void> {
    if (!this.preloadAudioValue && !this.isPlaying) return
    if (!this.hasMusicAudioTarget || !this.musicAudioTarget) return

    await this.fetchMusicAudio()

    this.musicAudioTarget.currentTime = 0

    if (this.isPlaying && this.musicAudioUrlValue) {
      this.musicAudioTarget.play()
    }
  }

  voiceChaptersUrlValueChanged(): void {
    if (this.voiceChaptersUrlValue) {
      fetch(this.voiceChaptersUrlValue, { credentials: 'same-origin' })
        .then((response) => response.json())
        .then((manifest) => {
          this.chaptersManifest = manifest
        })
    } else {
      this.chaptersManifest = undefined
    }
  }

  chaptersManifestChanged(): void {
    this.updateChapterTitle()
  }

  changeVoice(
    event: Event & {
      params: VoiceParams
      currentTarget: HTMLElement
    }
  ): void {
    event.preventDefault()

    this.voiceButtonLabelTarget.innerText = event.params.buttonLabel
    this.voiceAudioUrlValue = event.params.audioUrl
    this.voiceChaptersUrlValue = event.params.chaptersUrl
    this.currentVoiceIdValue = event.params.voiceId

    this.currentVoiceNameTarget.innerText = event.params.voiceName
    this.currentVoiceDescriptionTarget.innerText = event.params.voiceDescription
    this.currentVoiceFlagTarget.innerText = event.params.voiceFlagCode
    this.currentVoiceAuxilaryTarget.innerText = event.params.voiceAuxilaryText

    this.changeVoiceSelectorTargets.forEach((element) => {
      const checked = element === event.currentTarget
      element.setAttribute('aria-checked', checked.toString())
    })
  }

  changeMusic(
    event: Event & {
      params: MusicParams
      currentTarget: HTMLElement
    }
  ): void {
    event.preventDefault()

    this.musicAudioUrlValue = event.params.audioUrl
    this.musicButtonLabelTarget.innerText = event.params.buttonLabel

    this.changeMusicSelectorTargets.forEach((element) => {
      element.setAttribute('aria-checked', 'false')
    })

    event.currentTarget.setAttribute('aria-checked', 'true')
  }

  playPauseToggle(e: Event): void {
    e.preventDefault() // prevent space bar from scrolling the page
    this.isPlaying ? this.pause() : this.play()
  }

  async play(e?: Event): Promise<void> {
    e?.preventDefault()

    this.#pauseMedia()

    if (!this.voiceAudioTarget.src) {
      await this.fetchVoiceAudio()
    }

    if (this.hasMusicAudioTarget && !this.musicAudioTarget?.src) {
      await this.fetchMusicAudio()
    }

    this.voiceAudioTarget.play().catch(() => {
      this.voiceAudioTarget.pause()
    })

    if (
      this.hasMusicAudioTarget &&
      this.musicAudioTarget &&
      this.musicAudioUrlValue
    ) {
      this.musicAudioTarget.play().catch(() => {
        this.voiceAudioTarget.pause()
      })
    }
  }

  playing(): void {
    this.playTargets.forEach((element) => {
      element.classList.add('hidden')
    })
    this.pauseTargets.forEach((element) => {
      element.classList.remove('hidden')
    })

    if (this.hasTrackTarget) {
      this.selectedTrack?.setAttribute('data-paused', 'false')
    }
  }

  pause(e?: Event): void {
    e?.preventDefault()

    this.voiceAudioTarget.pause()
  }

  pausing(): void {
    // The HTML <audio> element emits a `pause` event automatically when it
    // reaches the end of the resource. If the controller should autoplay
    // the next track, no-op this event to prevent toggling play and pause buttons
    // and pausing the music while switching to the next track
    if (this.autoplayNextTrackValue) {
      const voiceAudioEnded =
        Math.abs(
          this.voiceAudioTarget.currentTime - this.voiceAudioTarget.duration
        ) < 0.00001

      if (voiceAudioEnded && this.nextTrack) return
    }

    this.pauseTargets.forEach((element) => {
      element.classList.add('hidden')
    })
    this.playTargets.forEach((element) => {
      element.classList.remove('hidden')
    })

    if (this.hasTrackTarget) {
      this.selectedTrack?.setAttribute('data-paused', 'true')
    }

    if (this.hasMusicAudioTarget && this.musicAudioTarget) {
      this.musicAudioTarget.pause()
    }
  }

  ending(): void {
    if (this.autoplayNextTrackValue) {
      if (this.nextTrack) {
        this.#playNextTrack()
      } else {
        this.#resetTrackList()
      }
    }
  }

  scrubBack(): void {
    this.voiceAudioTarget.currentTime -= 10.0
  }

  scrubForward(): void {
    this.voiceAudioTarget.currentTime += 10.0
  }

  scrub(): void {
    const percent = parseFloat(this.timelineTarget.value) / 100
    this.voiceAudioTarget.currentTime = percent * this.voiceAudioTarget.duration
  }

  updateChapterTitle(): void {
    const timestamp = this.voiceAudioTarget.currentTime
    const chapter = this.currentChapter(timestamp)

    this.currentChapterTitleValue = chapter?.titles[0]?.title
  }

  currentChapter(timestamp: number) {
    if (!this.chaptersManifest) return

    let chapter: ChaptersManifestChapter | undefined

    if (this.chaptersManifest.length === 1) {
      return this.chaptersManifest[0]
    }

    let low = 0
    let high = this.chaptersManifest.length - 1

    while (low < high) {
      const mid = Math.floor((low + high) / 2)
      chapter = this.chaptersManifest[mid]

      if (high === low + 1) {
        break
      } else if (chapter['start-time'] > timestamp) {
        high = mid
      } else {
        low = mid
      }
    }

    return chapter
  }

  updateTime(event: Event): void {
    // Amount the new timestamp is different from the previous timestamp, in milliseconds
    const delta = Math.abs(this.currentTimestampValue - event.timeStamp)

    // Threshold of how frequently to bother updating the UI. When scrubbing, many events
    // within the same millisecond get emitted, which causes noticeable lag.
    const threshold = 50

    if (delta > threshold) this.currentTimestampValue = event.timeStamp
  }

  syncMusicTimestamp(): void {
    if (!this.hasMusicAudioTarget || !this.musicAudioTarget) return

    this.musicAudioTarget.currentTime = this.voiceAudioTarget.currentTime
  }

  currentTimestampValueChanged(): void {
    this.updateChapterTitle()

    const currentTime = this.voiceAudioTarget.currentTime || 0
    const duration = this.voiceAudioTarget.duration || 0

    if (this.hasElapsedTimeTarget) {
      this.elapsedTimeTarget.innerText = this.formatDuration(
        Math.floor(currentTime)
      )
    }

    if (this.hasRemainingTimeTarget) {
      const remainingTime = Math.floor(duration) - Math.floor(currentTime)
      this.remainingTimeTarget.innerText = `-${this.formatDuration(
        remainingTime
      )}`
    }

    if (this.hasTimelineTarget) {
      const playPercent = duration ? 100 * (currentTime / duration) : 0
      this.timelineTarget.value = playPercent.toString()
    }
  }

  formatDuration(duration: number): string {
    // 123.456 => "2:03"
    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration % 60)

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  // The native Apple <audio> element is capable of playing both regular audio files (such as .mp3),
  // as well as HLS streams.
  //
  // If the browser does not support HLS natively, we need to use HLS.js to handle HLS.
  // However, HLS.js does not handle files that are just regular audio files, it ONLY handles HLS.
  //
  // So, we need to figure out what kind of audio is being requested. If it's not HLS, we need to
  // make sure to avoid HLS.js and tear down any HLS.js instance that was set up before.
  async updateAudio(
    url: string,
    hls: Hls | undefined,
    audioTarget: HTMLAudioElement
  ) {
    if (url.startsWith('//')) {
      // Account for relative protocol URLs
      // Relative protocols "cannot be parsed as a URL"
      url = `https:${url}`
    } else if (url.startsWith('/')) {
      // Account for paths instead of URLs
      url = `${window.origin}${url}`
    }

    const contentType = await this.#contentTypeWithCache(url)
    const isHls = contentType === 'application/vnd.apple.mpegurl'

    if (!isHls) hls?.destroy()

    if (!this.hlsModule) {
      await import('hls.js').then((module) => (this.hlsModule = module.default))
    }

    if (isHls && this.hlsModule.isSupported()) {
      if (!hls) {
        hls = new this.hlsModule()
      }

      hls.loadSource(url)
      hls.attachMedia(audioTarget)

      return hls
    } else if (contentType && audioTarget.canPlayType(contentType)) {
      audioTarget.src = url
    } else {
      alert('Your browser does not support playing this content')
      throw `Content-Type ${contentType} not supported`
    }
  }

  get isPlaying() {
    const playing = (element: HTMLElement) =>
      element.classList.contains('hidden')

    return this.playTargets.some(playing)
  }

  get nextTrack() {
    if (this.selectedTrack) {
      const currentTrackIndex = this.trackTargets.indexOf(this.selectedTrack)
      return this.trackTargets[currentTrackIndex + 1]
    }
  }

  #playNextTrack() {
    this.nextTrack?.click()
  }

  get selectedTrack() {
    const selected = (element: HTMLElement) =>
      element.getAttribute('aria-selected') === 'true'

    return this.trackTargets.find(selected)
  }

  #pauseMedia() {
    const players = document.querySelectorAll(
      'audio, video'
    ) as NodeListOf<HTMLMediaElement>

    players.forEach((player) => {
      player.pause()
    })
  }

  #resetTrackList() {
    if (!this.hasTrackTarget) return

    this.tracklistRestartedValue = true
    if (this.hasMusicAudioTarget && this.musicAudioTarget) {
      this.musicAudioTarget.currentTime = 0
    }
    this.trackTargets[0].click()
  }

  async #contentTypeWithCache(url: string): Promise<string | undefined> {
    if (Object.prototype.hasOwnProperty.call(this.contentTypes, url)) {
      return this.contentTypes[url]
    }

    const contentType = await this.#contentTypeFinder(url)
    this.contentTypes[url] = contentType

    return contentType
  }

  async #contentTypeFinder(url: string): Promise<string | undefined> {
    const parsedUrl = new URL(url)

    if (parsedUrl.origin === window.origin) {
      if (parsedUrl.pathname.endsWith('.mp3')) {
        // Skip web request, it's an mp3
        return 'audio/mpeg'
      } else if (parsedUrl.pathname.endsWith('.wav')) {
        // Skip web request, it's a wave file
        return 'audio/x-wav'
      }

      const hlsPaths = [
        '/audio/v1/hls',
        '/audio/v1/audio_recordings',
        '/audio/v1/music_albums',
      ]
      if (hlsPaths.includes(parsedUrl.pathname)) {
        // Skip web request, it's one of our HLS streams
        return 'application/vnd.apple.mpegurl'
      }
    } else if (parsedUrl.origin.endsWith('.r2.cloudflarestorage.com')) {
      const type = parsedUrl.searchParams.get('response-content-type')
      if (type) return type
    }

    // Only way to determine the content type is a HEAD request
    const response = await fetch(url, { method: 'HEAD' })
    const contentType = response.headers.get('Content-Type')
    return contentType?.toLowerCase()
  }
}
