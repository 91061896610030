import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="hw-combobox-url-value"
export default class extends Controller<HTMLFormElement> {
  navigate(event: Event): void {
    event.preventDefault()

    const input = this.element
      ?.closest('.hw-combobox')
      ?.querySelector('input[type="hidden"]') as HTMLInputElement | null

    const url = input?.value

    if (!url) throw new Error('url not found')

    if (window.Turbo) {
      window.Turbo.visit(url)
    } else {
      window.location.assign(url)
    }
  }
}
